@import 'src/styles/helpers';

.subservicesWrapper {
    padding: 64px 0;
    display: flex;
    flex-direction: column;
    gap: 32px;

    @include media(notMobile) {
        gap: 48px;
        padding: 120px 0;
    }

    @include media(portrait) {
        padding: 104px 0;
    }

    .sectionTitle {
        font-size: 14px;    
        line-height: 32px;
        letter-spacing: 0.183px;
        @include setProperty(color, var(--secondary-200), var(--secondary-500));

        @include media(desktopAll) {
            font-size: 16px;
        }
    }
}

.itemWrapper {
    position: relative;
    padding: 32px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include media(notMobile) {
        padding: 40px 0;
    }

    @include media(desktopAll) {
        padding: 40px 0 96px;
        align-items: flex-start;
    }
    
    .itemContent {
        display: flex;
        flex-direction: column;
        width: 100%;

        @include media(desktopAll) {
            gap: 24px;
        }


        .title {
            @include setProperty(color, var(--secondary-50), var(--secondary-500));

            @include media(mobile) {
                font-size: 28px;
                line-height: 32px;
            }
        }

        .description {
            height: 0;
            opacity: 0;
            font-size: 14px;
            line-height: 2;
            font-weight: 300;
            letter-spacing: 0.183px;
            @include setProperty(color, var(--secondary-100), var(--secondary-400));

            transition: all ease-in-out 0.3s;

            @include media(landscape) {
                font-size: 16px;
            }

            @include media(desktopAll) {
                height: auto;
                opacity: 1;
                font-size: 16px;
                max-width: 60%;
            }
        }
    }

    .animationBlock {
        display: none;

        @include media(landscape) {
            right: 150px;
        }

        @include media(desktopAll) {
            position: absolute;
            right: 100px;
            display: block;
            opacity: 1;
        }

        @include media(desktopSmall) {
            right: 220px;
        }

        &.mobile {
            display: block;
            height: 130px;
            align-self: center;
            opacity: 0;
            height: 0;

            transition: all ease-in-out 0.3s;

            @include media(desktopAll) {
                display: none;
            }
        }
    }

    .iconWrapper {
        width: 32px;
        height: 32px;
        border-radius: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all ease-in-out 0.5s, margin ease-in-out 0.8s, height ease-in-out 0.5s, width ease-in-out 0.5s;
        border-color: #262F2E;

        &.hidden {
            visibility: hidden;
        }

        @include media(notMobile) {
            width: 64px;
            height: 64px;
        }

        .arrowIcon {
            width: 32px;
            height: 32px;
            transition: all ease-in-out 0.5s, transform ease-in-out 0.8s, height ease-in-out 0.5s, width ease-in-out 0.5s;
            @include setProperty(color, var(--secondary-50), var(--secondary-500));

            @include media(notMobile) {
                width: 64px;
                height: 64px;
            }
        }
    }

    &.active{
        align-items: flex-start;

        .itemContent {
           gap: 24px; 
        }

        .description {
            height: auto;
            opacity: 1;
        }

        .iconWrapper {
            min-width: 40px;
            width: 40px;
            height: 40px;
            border: 1px solid #262F2E;

            @include media(notMobile) {
                min-width: 64px;
                width: 64px;
                height: 64px;
            }

            @include media(desktopAll) {
                margin: 64px -24px 0 0;
            }

            .arrowIcon {
                width: 16px;
                height: 16px;
                transform: rotate(45deg);
            }

            &:hover {
                @include setProperty(background-color, #262F2E, var(--primary-100));
    
                .arrowIcon {
                    transform: rotate(45deg) scale(1.5);
                }
            }
        }

        .animationBlock {
            height: auto;
            opacity: 1;

            &.mobile {
                height: 130px;
                margin-top: 16px;
            }
        }

        background: linear-gradient(78deg, #141414 1.5%, #141414 19.7%, #191919 69.77%, #141414 93.96%);
        @include setProperty(background, linear-gradient(78deg, #141414 1.5%, #141414 19.7%, #191919 69.77%, #141414 93.96%), transparent);
        backdrop-filter: blur(62.5px);
    }
}