@import 'src/styles/helpers';

.info {
  &__content {
    display: grid;
    gap: var(--margin-xxl);

    @include media(landscape) {
      gap: 200px;
    }

    @include media(desktopAll) {
      gap: 200px;
    }
  }
}

.title {
  @include setProperty(color, var(--secondary-50), var(--secondary-500));
  font-family: 'KharkivTone', sans-serif;
  font-size: 36px;
  line-height: 44px;
  letter-spacing: 1px;
  position: relative;
  z-index: 2;
  margin: 80px auto 0;
  text-align: center;

  @include media(tablet) {
    max-width: 552px;
  }

  @include media(desktopSmall) {
    max-width: 552px;
  }

  @include media(desktop) {
    max-width: 956px;
    font-size: 60px;
    line-height: 68px;
  }

  @include media(desktopBig) {
    max-width: 940px;
    font-size: 60px;
    line-height: 68px;
  }

  @include media(desktopHuge) {
    max-width: 958px;
    font-size: 60px;
    line-height: 68px;
  }

  mark {
    @include setProperty(color, var(--primary-500), var(--primary-900));
  }
}

.subtitle {
  position: relative;
  z-index: 2;
  font-size: 14px;
  line-height: 2;
  font-weight: 300;
  text-align: center;
  margin: 24px auto 0;
  @include setProperty(color, var(--secondary-50), var(--secondary-500));

  @include media(tablet) {
    max-width: 463px;
    margin-top: 40px;
  }

  @include media(desktopAll) {
    margin-top: 40px;
  }

  @include media(desktopSmall) {
    max-width: 459px;
  }

  @include media(desktop) {
    max-width: 484px;
    font-size: 16px;
  }

  @include media(desktopBig) {
    max-width: 493px;
    font-size: 16px;
  }

  @include media(desktopHuge) {
    max-width: 721px;
    font-size: 16px;
  }
}

.hero {
  @include pageHeaderPadding;
  position: relative;
  overflow: hidden;
  padding-bottom: 48px;
  padding-right: 24px;
  padding-left: 24px;

  @include media(tablet) {
    padding-bottom: 64px;
  }

  @include media(desktopAll) {
    padding-bottom: 64px;
  }

  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    @include setProperty(background, linear-gradient(179deg, rgba(22, 22, 22, 0.90) 0%, #141414 100%), linear-gradient(180deg, rgba(246, 246, 246, 0.79) 0%, #F6F6F6 100%));
    z-index: 1;
  }

  &__image {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
    z-index: 0;
    filter: blur(7px);
  }
}

.content {
  padding-top: 64px;
  padding-bottom: 64px;

  @include media(tablet) {
    padding-top: 120px;
    padding-bottom: 120px;
  }

  @include media(desktopAll) {
    padding-top: 120px;
    padding-bottom: 120px;
  }

  @include media(desktopHuge) {
    width: 1280px !important;
  }
}
