.wrapper {
    position: relative;
    width: 266px;
    height: 110px;
    overflow-y: clip;

    .left_block {
        position: absolute;
        transition: all ease-in-out 1s;
        width: 97px;
        height: 350px;
        top: -38px;
        right: 180px;
    }

    .center_block {
        position: absolute;
        transition: all ease-in-out 1s;
        width: 60px;
        height: 400px;
        top: -246px;
        right: 110px;
    }

    .right_block {
        position: absolute;
        transition: all ease-in-out 1s;
        width: 101px;
        height: 350px;
        right: 0;
        top: -4px;
    }
    &.active {
        
        .left_block {
            top: -218px;
            animation: leftBlockAnimation linear 1.5s;
        }

        .center_block {
            top: -66px;
            animation: centerBlockAnimation linear 1.5s;
        }

        .right_block {
            top: -184px;
            animation: rightBlockAnimation linear 1.5s;
        }
    }
}

@keyframes leftBlockAnimation {
    0% {
        top: -38px;
    }

    100% {
        top: -218px;
    }
}

@keyframes centerBlockAnimation {
    0% {
        top: -246px;
    }
    
    100% {
        top: -66px;
    }
}

@keyframes rightBlockAnimation {
    0% {
        top: -4px;
    }

    100% {
        top: -184px;
    }
}