.wrapper {
    position: relative;
    width: 266px;
    height: 110px;


    .window_init {
        position: absolute;
        width: 168px;
        height: 116px;
        top: 6px;
        right: 58px;
    }

    .window {
        position: absolute;
        opacity: 0;
        width: 168px;
        height: 116px;
        top: 6px;
        right: 58px;
    }

    .animated {
        transition: all ease-in-out 1s;
        opacity: 0;
    }
        
    .crossed_rectangle {
        position: absolute;
        top: 54px;
        right: 204px;
        width: 96px;
        height: 52px;
    }

    .squares {
        position: absolute;
        top: 44px;
        right: -10px;
        width: 71px;
        height: 20px;
    }

    .rectangle_small_first {
        position: absolute;
        top: 96px;
        right: 126px;
        height: 12px;
        width: 50px;
    }

    .rectangle_small_second {
        position: absolute;
        top: 96px;
        right: 80px;
        height: 12px;
        width: 50px;
    }

    .rectangle_filled {
        position: absolute;
        top: 96px;
        right: -30px;
        width: 80px;
        height: 12px;
    }
    
    .content {
        position: absolute;
        top: 28px;
        right: 112px;
        width: 46px;
        height: 50px;
    }

    .text {
        position: absolute;
        top: 36px;
        right: 188px;
        width: 24px;
        height: 6px;
    }

    .text_right {
        position: absolute;
        top: 68px;
        right: 68px;
        width: 24px;
        height: 6px;
        transform: rotateY(180deg);
    }

    &.active {

        .animated {
            opacity: 1;
        }

        .window_init {
            opacity: 0;
            transition: all ease-in-out 1s;
        }

        .window {
            opacity: 1;
            transition: all ease-in-out 1s;
        }

        .crossed_rectangle {
            right: 194px;
        }

        .squares {
            right: 24px;
        }

        .rectangle_filled {
            right: 4px;
        }
    }
}