@import 'src/styles/helpers';
.postsSection {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 32px;
    padding: 64px 24px;
    overflow: hidden;

    header {
        display: flex;
        align-items: center;
        align-self: stretch;

        .title {
            flex: 1 0 0;
            text-transform: uppercase;
            @include setProperty(color, var(--secondary-50), var(--secondary-500));
        }

        .headerButton {
            color: var(--primary-500);    

            .icon {
                width: 24px;
                height: 24px;
            }
        }

        @include media(portrait) {
            padding-right: 64px;
        }

        @include media(landscape) {
            padding-right: 72px;
        }
    }

    .posts {
        display: grid;
        gap: 32px;
        place-content: flex-start;

        @include media(notMobile) {
            grid-template-columns: repeat(3, 1fr);
        }

        @include media(portrait) {
            gap: 16px;
            width: 100%;
        }
    }

    @include media(portrait) {
        padding: 104px 64px;
        gap: 80px;
    }

    @include media(landscape) {
        padding: 120px 72px;
        gap: 92px;
    }

    @include media(desktopAll) {
        padding: 120px 320px;
        gap: 80px;
    }

    @include media(desktopSmall) {
        padding: 120px 120px;
        gap: 92px;
    }

    @include media(desktop) {
        padding: 120px 200px;
    }
}


.servicePost {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 32px;

    .postImg {
        aspect-ratio: 3 / 2;
        width: 100%;
        height: auto;
    }   

    .content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 12px;

        .title {
            font-family: 'KharkivTone', sans-serif;
            font-size: 20px;
            line-height: 28px;

            mark {
                @include setProperty(color, var(--primary-500), var(--primary-900));
            }
        }

        .info {
            font-size: 12px;
            line-height: 12px;
            letter-spacing: 0.183px;
            font-weight: 300;
            @include setProperty(color, var(--secondary-50), var(--secondary-500));

            a {
                text-decoration: underline;
            }
            
            .infoDivider {
                padding: 0 12px;
            }
        }
    }
}