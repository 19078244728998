@import 'src/styles/helpers';

.socialCardsSection {
    display: flex;
    flex-direction: column;
    padding: 64px 24px 0;

    @include media(portrait) {
        padding: 104px 8px 24px;
    }

    @include media(landscape) {
        padding: 120px 8px 0;
        flex-direction: row;
    }

    @include media(desktopAll) {
        padding: 144px 8px 0; 
        flex-direction: row;
    }

    @include media(desktopBig) {
        padding: 144px 216px 0;
    }
}

.socialCardItem {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 16px;
    padding: 32px 0;

    &:first-of-type {
        border-bottom: 1px solid;
        @include setProperty(border-color, var(--dark-line-shape), var(--light-line-shape));

        @include media(landscape) {
            border-bottom: none;
            border-right: 1px solid;
        }

        @include media(desktopAll) {
            border-bottom: none;
            border-right: 1px solid;
        }
    }

    @include media(notMobile) {
        padding: 32px 56px;
        flex-direction: row;
        gap: 40px;
    }

    @include media(desktopAll) {
        padding: 64px 56px;
    }

    .socialIcon {
        width: 64px;
        height: 64px;

        @include media(notMobile) {
            min-width: 96px;
            width: 96px;
            height: 96px;
        }
    }

    @include media(notMobile) {
        gap: 32px;
    }

    @include media(desktopAll) {
        gap: 40px;
    }

    .content {
        display: flex;
        flex-direction: column;
        gap: 24px;

        .description {
            @include media(landscape) {
                font-size: 24px;
                line-height: 32px;
            }

            @include media(desktopAll) {
                font-size: 24px;
                line-height: 32px;
            }
        }

        .socialLink {
            font-size: 16px;
            line-height: 16px;
            text-transform: uppercase;
            color: var(--primary-500);
            padding: 12px 0;

            .linkIcon {
                width: 24px;
                height: 24px;
            }
        }
    }
}