@import 'src/styles/helpers';

.item {
  display: grid;
  justify-content: center;
  text-align: center;
  position: relative;

  @include media(landscape) {
    gap: var(--margin-md);
  }

  @include media(desktopAll) {
    grid-template-columns: 72px 1fr;
    text-align: left;
    gap: var(--margin-xl);
  }
}

.icon {
  width: 72px;
  height: 72px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  @include setProperty(border, 1px solid var(--dark-line-low-accent), 1px solid var(--light-line-low-accent));
  margin: 0 auto;
  @include setProperty(background, var(--dark-bg), var(--light-bg));

  img,
  svg {
    max-width: 28px;
    max-height: 28px;
  }
}

.title {
  font-family: 'KharkivTone', sans-serif;
  font-size: 16px;
  line-height: 24px;
  margin: 24px 0 16px;

  @include media(landscape) {
    margin-top: 0;
  }

  @include media(desktopAll) {
    margin-top: 0;
  }
}

.text {
  font-size: 14px;
  line-height: 2;
}