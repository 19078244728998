.wrapper {
    $animation-duration: 1.5s;
    position: relative;
    width: 266px;
    height: 110px;


    .phone_crossed {
        position: absolute;
        width: 66px;
        height: 124px;
        top: 2px;
        right: 102px;
    }

    .animated {
        transition: all ease-in-out 1s;
        opacity: 0;
    }

    .phone_left {
        width: 48px;
        height: 72px;
        position: absolute;
        top: 28px;
        right: 102px;
    }

    .rectangle_crossed {
        position: absolute;
        width: 26px;
        height: 16px;
        left: -14px;
        top: 42px;
    }

    .phone_right {
        height: 72px;
        width: 51px;
        position: absolute;
        top: 28px;
        right: 102px;
    }

    .phone_text_top {
        height: 100px;
        width: 61px;
        position: absolute;
        top: 14px;
        right: 102px;
    }

    .phone_text_bottom {
        height: 100px;
        width: 61px;
        position: absolute;
        top: 14px;
        right: 102px;
    }

    .rectangle_left {
        width: 42px;
        height: 20px;
        position: absolute;
        top: 64px;
        right: 204px;
        opacity: 0;
    }

    .rectangle_right {
        width: 47px;
        height: 20px;
        position: absolute;
        right: 26px;
        top: 40px;
        opacity: 0;
    }

    &.active {

        .animated {
            opacity: 1;
        }

        .phone_text_top {
            right: 176px;
            animation: phoneTextTopAnimation linear $animation-duration;
        }

        .phone_text_bottom {
            right: 35px;
            animation: phoneTextBottomAnimation linear $animation-duration;
        }

        .phone_left {
            right: 232px;
            animation: phoneLeftAnimation linear $animation-duration;
        }

        .phone_right {
            right: -12px;
            animation: phoneRightAnimation linear $animation-duration;
        }

        .rectangle_crossed {
            transition: opacity 0.3s ease-in-out 1.5s;
        }

        .rectangle_left {
            opacity: 1;
            animation: showRectangle linear 2s;
        }

        .rectangle_right {
            opacity: 1;
            animation: showRectangle linear 2s;
        }
    }
}

@keyframes phoneTextTopAnimation {
    0% {
        right: 102px;
    }

    100% {
        right: 176px;
    }
}

@keyframes phoneTextBottomAnimation {
    0% {
        right: 102px;
    }

    100% {
        right: 35px;
    }
}

@keyframes phoneLeftAnimation {
    0% {
        right: 102px;
    }

    100% {
        right: 232px;
    }
}

@keyframes phoneRightAnimation {
    0% {
        right: 102px;
    }

    100% {
        right: -12px;
    }
}

@keyframes showRectangle {
    0% {
        opacity: 0;
    }
    80% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}