@import 'src/styles/helpers';

@mixin overlay($backdropBlur: 0) {
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur($backdropBlur);
  @include setProperty(background, rgba(25, 32, 31, 0.05), rgba(246, 246, 246, 0.01));
}

.hero {
  padding: calc(132px + 72px) 12px 124px;
  position: relative;
  z-index: 0;
  overflow: hidden;

  @include media(portrait) {
    padding-bottom: 221px;
  }

  @include media(landscape) {
    padding-bottom: 153px;
  }

  @include media(desktopAll) {
    padding: calc(132px + 96px) 12px 232px;
  }

  &__images {
    pointer-events: none;

    &__overlay {
      @include overlay(62.5px);

      height: calc(100% + 200px);
    }

    .vector {
      position: absolute;
      z-index: 2;

      &.halo {
        height: 100%;
        top: 0;
        left: calc(50% - 520px);

        @include media(tablet) { 
          left: calc(50% - 660px);
        }

        @include media(desktopAll) {
          width: 100%;
          left: unset;
        }
      }

      &_blur {

        &_left {
          background: url('/media/industry_hero_ellipse_left_dark.svg') no-repeat center;
          width: 830px;
          height: 79px;
          right: 30vw;
          bottom: 40px;
          z-index: 0;

          &.hidden {
            display: none;
          }

          @include media(tablet) {
            right: 50vw;
          }

          @include media(desktopAll) {
            right: 60vw;
          }

          @include media(desktopBig) {
            right: 70vw;
          }
        }

        &_right {
          background: url('/media/industry_hero_ellipse_right_dark.svg') no-repeat center;
          width: 1067px;
          height: 109px;
          z-index: 0;
          display: none;

          &.hidden {
            display: none;
          }

          @include media(tablet) {
            top: 160px;
            display: block;
            left: 50vw;
          }

          @include media(desktopAll) {
            top: 160px;
            display: block;
            left: 60vw;
          }

          @include media(desktopBig) {
            left: 70vw;
          }
        }
      }

      &_left {
        width: 878px;
        height: 633px;
        right: calc(50vw + 40px);
        bottom: -120px;

        @include media(desktopBig) {
          right: 65vw;
        }
      }

      &_center {
        width: 120px;
        height: 95px;
        left: calc(50vw + 10px);
        top: 50%;
      }

      &_right {
        width: 583px;
        height: 288px;
        top: 20px;
        left: calc(50vw + 40px);

        @include media(tablet) {
          top: 100px;
        }

        @include media(desktopAll) {
          top: 100px;
        }

        @include media(desktop) {
          left: calc(60vw);
        }

        @include media(desktopBig) {
          left: 70vw;
        }
      }
    }
  }

  &__title {
    font-family: 'KharkivTone', sans-serif;
    font-size: 28px;
    line-height: 44px;
    text-transform: uppercase;
    text-align: center;
    position: relative;
    z-index: 3;

    @media screen and (max-width: 374px) {
      font-size: 24px;
      line-height: 30px;
    }

    @include media(tablet) {
      max-width: 700px;
      margin: 0 auto;
      line-height: 36px;
    }

    @include media(desktopAll) {
      max-width: 745px;
      margin: 0 auto;
      font-size: 60px;
      line-height: 68px;
      letter-spacing: 1px;
    }

    mark {
      @include setProperty(color, var(--primary-500), var(--primary-900));
    }
  }

  &__button {
    margin: 0 auto;
    position: relative;
    z-index: 3;

    font-size: 16px;
    line-height: 16px;

    @include media(tablet) {
      font-size: 24px;
      line-height: 24px;
    }

    @include media(desktopAll) {
      font-size: 24px;
      line-height: 24px;
    }
  }

  &__text {
    margin: 28px 12px 32px;
    font-size: 14px;
    line-height: 2;
    text-align: center;
    position: relative;
    z-index: 3;

    mark {
      @include setProperty(color, var(--primary-500), var(--primary-900));
    }

    @include media(portrait) {
      max-width: 640px;
      margin: var(--margin-lg) auto;
    }

    @include media(landscape) {
      max-width: 474px;
      margin: var(--margin-lg) auto;
    }

    @include media(desktopAll) {
      max-width: 474px;
      margin: 20px auto 40px;
      font-size: 16px;
    }

    &.custom {
      max-width: 396px;
    }
  }

  &.customHero {
    margin-top: 96px;
    padding: 92px 24px 92px;

    @include media(notMobile) {
      padding: 120px 0 92px;
    }

    @include media(desktopAll) {
      padding: 144px 0 120px; 
    }

    button {
      font-size: 16px;
      line-height: 16px;
    }
  }
}

.services {
  display: grid;
  gap: 18px;
  position: relative;
  z-index: 1;
  padding: var(--margin-xxl) 0;

  @include media(landscape) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 0;
  }

  @include media(desktopAll) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 0;
  }

  &__column {
    display: flex;
    flex-direction: column;

    &:first-of-type {

      .serviceCard {

        &:first-of-type {
          margin-top: 0;
        }

        @include media(landscape) {
          &:last-of-type {

            &:after {
              display: none;
            }
          }
        }

        @include media(desktopAll) {
          &:last-of-type {

            &:after {
              display: none;
            }
          }
        }
      }
    }

    &:last-of-type {

      @include media(landscape) {
        @include setProperty(border-left, 1px solid var(--dark-line-low-accent), 1px solid var(--light-line-low-accent));
        padding-top: 88px;

        .serviceCard {

          &:nth-last-child(2) {
            order: 1;
          }
        }
      }

      @include media(desktopAll) {
        @include setProperty(border-left, 1px solid var(--dark-line-low-accent), 1px solid var(--light-line-low-accent));
        padding-top: 88px;

        .serviceCard {

          &:nth-last-child(2) {
            order: 1;
          }
        }
      }

      .serviceCard {

        &:nth-last-child(2) {

          &:after {
            display: none;
          }
        }
      }
    }
  }
}

.serviceCard {
  position: relative;
  margin-bottom: 1px;
  padding: 40px 0;
  min-height: 300px;

  .overlay {
    @include overlay;
    overflow: hidden;

    opacity: 0;
    transition: opacity 0.3s ease 0s;

    &:before {
      content: '';
      position: absolute;
      z-index: 0;
      background: url('/media/joinUs_after.svg') no-repeat center;
      width: 408px;
      height: 50px;
      bottom: 12px;
      left: 50%;
      transform: translateX(-50%);
      filter: blur(65px);
    }
  }

  &__content {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    text-align: center;
    gap: 16px;
    z-index: 2;
  }


  &:after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -1px;
    width: 100%;
    height: 1px;
    @include setProperty(background, var(--dark-line-low-accent), var(--light-line-low-accent));
  }

  &:hover {
    .overlay {
      opacity: 1;
    }
  }

  @include media(landscape) {
    justify-content: center;
    height: 380px;
    padding: 40px 56px;
  }

  @include media(desktopAll) {
    justify-content: center;
    height: 380px;
    padding: 40px 56px;
  }

  &__icon {
    width: 64px;

    img {
      width: 100%;
    }

    @include media(mobile) {
      padding-top: 40px;
    }
  }

  &__title {
    @include setProperty(color, var(--secondary-50), var(--secondary-500));
    font-family: 'KharkivTone', sans-serif;
    font-size: 16px;
    line-height: 24px;

    @include media(desktopAll) {
      font-size: 20px;
      line-height: 28px;
    }
  }

  &__text {
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    line-height: 2;
    letter-spacing: 0.183px;

    @include media(desktopBig) {
      font-size: 16px;
    }

    @include media(desktopHuge) {
      font-size: 16px;
    }
  }
}

.joinUs {
  @include setProperty(background, linear-gradient(41deg, #141414 0%, #191919 73.83%, #19201F 100%), linear-gradient(41deg, rgba(229, 229, 229, 0.10) 0%, #F5F5F5 73.83%));
  position: relative;
  @include scrollOutOfContainer;
  overflow-x: hidden !important;
  padding: 76px 56px;
  text-align: center;

  @include media(landscape) {
    margin-right: 0;
    margin-left: 0;
    @include setProperty(border-left, 1px solid var(--dark-line-low-accent), var(--light-line-low-accent));
    padding: 40px 56px;
    height: 380px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &:before {
    content: '';
    position: absolute;
    z-index: 0;
    background: url('/media/joinUs_before.svg') no-repeat center;
    width: 84px;
    height: 22px;
    left: 50%;
    top: 11px;
  }

  &:after {
    content: '';
    position: absolute;
    z-index: 0;
    background: url('/media/joinUs_after.svg') no-repeat center;
    width: 408px;
    height: 50px;
    bottom: 12px;
    left: 50%;
    transform: translateX(-50%);
  }

  &__content {
    position: relative;
    z-index: 2;
    display: grid;
    gap: 32px;
  }

  &__overlay {
    @include overlay(62.5px);
  }

  &__title {
    @include setProperty(color, var(--primary-500), var(--primary-900));
    font-size: 24px;
    line-height: 32px;
    font-family: 'KharkivTone', sans-serif;

    @include media(desktopAll) {
      font-size: 32px;
      line-height: 40px;
    }
  }

  &__text {
    font-size: 14px;
    line-height: 2;
    max-width: 442px;
    margin: 0 auto;
  }

  &__button {
    margin: 0 auto;

    @media screen and (max-width: 374px) {
      font-size: 12px;
    }
  }
}

.cases {
  padding-top: 64px;

  @include media(tablet) {
    padding-top: 96px;
  }

  @include media(desktopAll) {
    padding-top: 96px;
  }
}