@import 'src/styles/helpers';

.howWeWork {
  position: relative;
  padding: 120px 0;
  max-width: 1040px;
  display: grid;
  gap: 100px;

  @include media(landscape) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    padding: 100px 0;
    position: relative;
    align-items: flex-start;
  }

  @include media(desktopAll) {
    margin: 0 auto;
    display: grid;
    grid-template-columns: 0.88fr 1fr;
    padding-left: 0 !important;
    padding-right: 0 !important;
    align-items: flex-start;
    position: relative;
  }

  &__info {
    display: flex;
    flex-direction: column;
    align-items: center;

    @include media(landscape) {
      position: sticky;
      top: 16px;
    }

    @include media(desktopAll) {
      position: sticky;
      top: 16px;
      display: block;
      width: 380px;
    }
  }

  &__content {
    display: grid;
    gap: 96px;

    @include media(desktopAll) {
      margin: 0 auto;
      position: relative;
      gap: 96px;

      &:before {
        content: '';
        position: absolute;
        left: calc(72px / 2);
        top: 0;
        width: 1px;
        height: 100%;
        @include setProperty(background, var(--dark-line-low-accent), var(--light-line-low-accent));

      }
    }
  }

  &__button {
    height: 56px;
  }

  &__title {
    text-align: center;
    font-size: 28px;
    line-height: 36px;

    @include media(portrait) {
      font-size: 40px;
      line-height: 48px
    }

    @include media(desktopAll) {
      font-size: 40px;
      line-height: 48px;
      text-align: left;
    }
  }

  &__subtitle {
    margin: 24px 0 40px;
    font-size: 16px;
    font-weight: 300;
    line-height: 2;
    letter-spacing: 0.183px;
    text-align: center;

    @include media(landscape) {
      max-width: 380px;
    }

    @include media(desktopAll) {
      text-align: left;
    }
  }
}
