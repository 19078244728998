.wrapper {
    position: relative;
    width: 266px;
    height: 110px;

    .window {
        width: 150px;
        height: 102px;
        position: absolute;
        top: 3px;
        right: 57px;
    }

    .side_content {
        position: absolute;
        width: 251px;
        height: 86px;
        top: 14px;
        right: 4px;
    }

    .filled_rectangle {
        height: 10px;
        width: 44px;
        position: absolute;
        top: 76px;
        right: 74px;
    }

    .framed_cube {
        position: absolute;
        width: 58px;
        height: 58px;
        top: 32px;
        right: 68px;
        opacity: 0;
    }

    .crossed_squire {
        position: absolute;
        height: 24px;
        width: 30px;
        top: 28px;

        &.first {
            right: 72px;
        }

        &.second {
            right: 118px;
        }

        &.third {
            right: 164px;
        }
    }

    .text_small {
        position: absolute;
        height: 8px;
        width: 74px;
        top: 60px;
        right: 122px;
    }

    .text_large {
        position: absolute;
        height: 26px;
        width: 58px;
        top: 40px;
        right: 138px;
        opacity: 0;
    }

    &.active {

        .filled_rectangle {
            right: 154px;
            height: 8px;
            animation: filledRectangleAnimation linear 0.5s;
        }

        .framed_cube {
            opacity: 1;
            transition: opacity 0.3s ease-in-out 2.5s;
        }

        .crossed_squire {

            &.first {
                transform: scale(1.8, 2);
                right: 80px;
                top: 40px;
                opacity: 0;
                transition: all 0.5s ease-in-out 2s, opacity 0.3s ease-in-out 2.5s;
            }

            &.second,
            &.third {
                opacity: 0;
                transition: opacity 0.3s ease-in-out 0.5s;
            }
        }

        .text_small {
            opacity: 0;
            transition: opacity 0.5s ease-in-out 1s;
        }

        .text_large {
            opacity: 1;
            transition: opacity 0.5s ease-in-out 1s;
        }
    }
}

@keyframes filledRectangleAnimation {
    0% {
        right: 74px; 
    }

    100% {
        right: 154px;
        height: 8px;
    }
}