@import '../../styles/helpers';

.main {
  width: 100vw;
  overflow: hidden;

  &_withSidebar {
    display: flex;
    overflow: hidden;

    &__content {
      width: calc(100vw - 144px);

      @include media(desktopHuge) {
        width: calc(100vw - 200px);
      }

      @include media(desktopBig) {
        width: calc(100vw - 200px);
      }

      @include media(landscape) {
        width: calc(100vw - 120px);
      }

      @include media(portrait) {
        width: 100%;
      }

      @include media(mobile) {
        width: 100%;
      }
    }
  }
}

.section {
  position: relative;

  .sidebar__visual1 {
    position: absolute;
    right: 0;
    top: 100px;
    opacity: 0;
    transition: all 0.3s ease;
    pointer-events: none;

    @include media(portrait) {
      display: none;
    }

    @include media(mobile) {
      display: none;
    }

    &.visible {
      opacity: 1;
      transition: all 0.3s ease 1.1s;
    }

    .scroller {
      position: absolute;
      top: -50px;
      right: 176px;
      width: 28px;
      height: 105px;

      @include media(desktopAll) {
        right: 200px;
      }

      @include media(desktopBig) {
        right: 258px;
      }

      @include media(desktopHuge) {
        right: 258px;
      }
    }

    .lines {
      position: absolute;
      right: 5px;
      top: -152px;
      width: 186px;
      height: 324px;

      @include media(desktopAll) {
        right: 28px;
      }

      @include media(desktopBig) {
        right: 84px;
      }

      @include media(desktopHuge) {
        right: 84px;
      }

      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .sidebar__visual2 {
    position: absolute;
    opacity: 0;
    transition: all 0.3s ease;
    width: 400px;
    height: 455px;
    display: none;
    right: -215px;
    pointer-events: none;
    top: 180px;

    @include media(landscape) {
      display: block;
      top: 235px;
    }

    @include media(desktopAll) {
      display: block;
      top: 180px;
    }

    @include media(desktopHuge) {
      top: 250px;
    }

    &.visible {
      opacity: 1;
      transition: all 0.3s ease 0.7s;
    }
  }
}

.sidebar {
  top: 96px;
  width: 144px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 43px 0;
  position: relative;
  @include setProperty(border-image, linear-gradient(0, var(--dark-line-low-accent) 96%, var(--dark-bg) 100%), linear-gradient(0, var(--tertiary-100) 96%, var(--light-bg) 100%));
  border-left-style: solid;
  border-left-width: 1px;
  border-image-slice: 1 !important;

  @include media(desktopHuge) {
    width: 200px;
  }

  @include media(desktopBig) {
    width: 200px;
  }

  @include media(landscape) {
    top: 72px;
    width: 120px;
    padding: 28px 0;
  }

  @include media(portrait) {
    display: none;
  }

  @include media(mobile) {
    display: none;
  }

  &__dot {
    width: 6px;
    height: 6px;
    position: absolute;
    left: -3.5px;
    transition: top .7s cubic-bezier(0, .4, .43, 1.27);
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translateY(-3px);
    @include setProperty(background, linear-gradient(90deg, #9BFFF0 0%, rgba(247, 255, 254, 0.18) 100%), var(--secondary-500));
    border-radius: 50%;
    opacity: 0.4;

    &_trail, &_trail2 {
      opacity: 0.3;
      transition-delay: 0.03s;
    }

    &_trail2 {
      opacity: 0.2;
      transition-delay: 0.04s;
    }

    img, svg {
      width: 100%;
      height: 100%;
    }
  }
}
